import * as actionTypes from '../actions/actionsTypes';

const initialState = {
  hiddenColumns: {
    data: []
  },
};

export const HiddenColumnsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.ADD_HIDDEN_COLUMNS:
      return {
        ...state,
        hiddenColumns: action.payload,
      };
    case actionTypes.FETCH_HIDDEN_COLUMNS:
      return {
        ...state,
        hiddenColumns: action.payload,
      };
    default:
      return state;
  }
};
