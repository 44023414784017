import * as React from "react";
import { styled, alpha } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import InputBase from "@mui/material/InputBase";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import Avatar from "@mui/material/Avatar";
import Stack from "@mui/material/Stack";
import CloseIcon from "@mui/icons-material/Close";
import { calculateNewValue } from "@testing-library/user-event/dist/utils";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import * as actions from "../../../src/actions/actions";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 1),
  color: "#676767",
  borderRadius: "14px",
  border: "1px solid transparent",
  "&:focus": {
    border: "1px solid #C8DAEE",
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "auto",
  },
  [theme.breakpoints.down("sm")]: {
    marginLeft: "0px",
    width: "calc(100% - 40px)",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  top: "0px",
  right: "0px",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  color: "#356DAD",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    borderRadius: "32px",
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    width: "292px",
    [theme.breakpoints.up("md")]: {
      width: "328px",
      transition: "none",
      padding: "7px 16px 8px 16px",
    },
    [theme.breakpoints.down("md")]: {
      width: "264px",
      transition: "none",
      padding: "7px 16px 8px 16px",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      transition: "none",
      padding: "7px 16px 8px 16px",
    },
  },
}));

const Topbar = ({ drawerState, toggleDrawer }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [showMobSearch, setShowMobSearch] = React.useState(false);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const [logout, setlogout] = React.useState(null);
  const isMenuOpen = Boolean(anchorEl);

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobSearchOpen = () => {
    setShowMobSearch(true);
  };

  const handleMobSearchClose = () => {
    setShowMobSearch(false);
  };

  const handleLogout = () => {
    const requestData = {
      token: localStorage.getItem("accessToken"),
    };
    dispatch(actions.fetchlogout(requestData));
    localStorage.clear();
    localStorage.removeItem(localStorage.getItem("accessToken"));
    window.history.pushState(null, "", "/");
    navigate(0);
  };

  React.useEffect(() => {
    if (drawerState) {
      const icon3 = document.getElementById("icon3");
      icon3.classList.toggle("open");
    }
  }, [drawerState]);

  const handleDrawerButton = () => {
    toggleDrawer(!drawerState);
    const icon3 = document.getElementById("icon3");
    icon3.classList.toggle("open");
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const menuId = "primary-search-account-menu";
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      id={menuId}
      keepMounted
      transformOrigin={{ horizontal: "right", vertical: "top" }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleMenuClose}>Profile</MenuItem>
      <MenuItem onClick={handleMenuClose}>My account</MenuItem>
      <MenuItem onClick={handleLogout}>Logout</MenuItem>
    </Menu>
  );

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static" className="ty_custom_topbar">
        <Toolbar sx={{ gap: { md: "8px" } }}>
          <IconButton
            disableRipple
            onClick={handleDrawerButton}
            sx={{
              display: { lg: "none", md: "flex" },
            }}
          >
            <Box component="div" id="icon3">
              {[...Array(9)].map((_, index) => (
                <Box key={index} component="span"></Box>
              ))}
            </Box>
          </IconButton>
          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{ display: { xs: "none", sm: "block" } }}
          ></Typography>
          <Box sx={{ flexGrow: 1 }} />
          {/* <Search
            className="top_searc_bar"
            sx={{
              display: { md: "flex", sm: "flex", xs: "none" },
            }}
          >
            <StyledInputBase
              placeholder="Search"
              inputProps={{ "aria-label": "search" }}
            />
            <SearchIconWrapper>
              <SearchIcon />
              <img src="/assets/icons/Search.svg" />
            </SearchIconWrapper>
          </Search> */}
          <Box
            sx={{
              display: { md: "flex", sm: "flex", xs: "flex" },
              alignItems: "center",
              //gap: "12px",
              gap: { lg: "12px", md: "6px", sm: "6px" },
            }}
          >
            {/* <IconButton
              size="large"
              aria-label="show 4 new mails"
              color="inherit"
              className="ty_topbar_notification"
              disableRipple
              sx={{
                display: { md: "flex", sm: "flex", xs: "none" },
              }}
            >
              <img src="/assets/icons/phone.svg" />
            </IconButton> */}
            {/* <IconButton
              size="large"
              aria-label="show 17 new notifications"
              color="inherit"
              disableRipple
              className="ty_topbar_notification"
              sx={{
                display: { md: "flex", sm: "flex", xs: "none" },
              }}
            >
              <img src="/assets/icons/gift.svg" />
            </IconButton> */}
            {/* <IconButton
              size="large"
              aria-label="show 17 new notifications"
              color="inherit"
              disableRipple
              className="ty_topbar_notification"
              sx={{
                display: { md: "flex", sm: "flex", xs: "none" },
              }}
            >
              <img src="/assets/icons/question.svg" />
            </IconButton> */}
            {/* <IconButton
              size="large"
              aria-label="show 17 new notifications"
              color="inherit"
              onClick={handleMobSearchOpen}
              disableRipple
              className="ty_topbar_notification"
              sx={{
                display: { md: "none", sm: "none", xs: "none" },
              }}
            >
              <img src="/assets/icons/Search.svg" />
            </IconButton> */}
            <IconButton
              size="large"
              edge="end"
              sx={{
                gap: { lg: "0px", md: "5px" },
                paddingRight: "25px",
                display: "none",
              }}
              disableRipple
              aria-label="account of current user"
              aria-controls={menuId}
              aria-haspopup="true"
              onClick={handleProfileMenuOpen}
              color="inherit"
            >
              <Stack direction="row">
                <Avatar
                  variant="rounded"
                  sx={{
                    borderRadius: "8px",
                  }}
                />
                <Stack
                  direction="column"
                  alignItems="flex-start"
                  justifyContent="center"
                  sx={{
                    display: { lg: "flex", md: "none", sm: "none" },
                    marginRight: { lg: "14px", md: "0px" },
                    paddingLeft: { lg: "2px", md: "2px", sm: "0px", xs: "0px" },
                    marginLeft: { lg: "8px", md: "8px", sm: "0px", xs: "0px" },
                  }}
                >
                  <Typography
                    variant="body1"
                    noWrap
                    component="div"
                    sx={{
                      display: {
                        xl: "flex",
                        lg: "flex",
                        md: "none",
                        sm: "none",
                        xs: "none",
                      },
                      lineHeight: "19.2px",
                      color: "#356DAD",
                      fontWeight: "600",
                    }}
                  >
                    Chris
                  </Typography>
                  <Typography
                    variant="caption"
                    noWrap
                    component="div"
                    sx={{
                      display: {
                        xl: "flex",
                        lg: "flex",
                        md: "none",
                        sm: "none",
                        xs: "none",
                      },
                      fontSize: "14px",
                      lineHeight: "16.8px",
                      color: "#848484 ",
                      letterSpacing: "0",
                      fontWeight: "600",
                    }}
                  >
                    Senior Member
                  </Typography>
                </Stack>
              </Stack>
              <Box
                className="menu_arrow"
                component="div"
                sx={{
                  display: { lg: "flex", md: "flex", sm: "flex", xs: "none" },
                  marginRight: "0px",
                  marginLeft: { md: "0", sm: "10px" },
                }}
              >
                <img src="/assets/icons/down-arrow.svg" />
              </Box>
            </IconButton>
          </Box>
        </Toolbar>
        <Box
          className={showMobSearch ? "mob_search_bar open" : "mob_search_bar"}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Search
              className="top_searc_bar"
              sx={{
                display: { md: "none", sm: "none", xs: "flex" },
              }}
            >
              <StyledInputBase
                placeholder="Search"
                inputProps={{ "aria-label": "search" }}
              />
              <SearchIconWrapper>
                {/* <SearchIcon /> */}
                <img src="/assets/icons/Search.svg" />
              </SearchIconWrapper>
            </Search>
            <CloseIcon
              sx={{ display: { sm: "none" }, color: "#42566C" }}
              onClick={handleMobSearchClose}
              aria-label={"close drawer"}
            />
          </Box>
        </Box>
      </AppBar>
      {renderMenu}
    </Box>
  );
};

export default Topbar;
