import * as actionTypes from '../actions/actionsTypes';

const initialState = {
  penaltyList: {
    data: []
  },
  allPenalties: {
	    data: []
	  },
};

export const penaltyListReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_PENALTY_LIST:
      return {
        ...state,
        penaltyList: action.payload,
      };
    case actionTypes.FETCH_ALL_PENALTIES:
        return {
          ...state,
          allPenalties: action.payload,
        };
    default:
      return state;
  }
};
